<template>
  <div class="ps-page--my-account">
    <div class="ps-my-account">
      <div class="ps-container">
        <form class="ps-form--account ps-tab-root">
          <ul class="ps-tab-list">
            <li class="active">
              <a href="#verify">Verify your phone number</a>
            </li>
          </ul>
          <div class="ps-tabs">
            <div class="ps-tab active" id="verify">
              <div class="ps-form__content">
                <h5>Please enter 4 digit verification code</h5>
                <div v-if="error.message" class="alert alert-danger" role="alert">
                  {{error.message}}
                </div>
                <div class="form-group">
                  <input class="form-control" type="number" v-model="form.verification_code" placeholder="verification code" />
                </div>
                <div class="form-group submit">
                  <button @click.prevent="onSubmit()" class="ps-btn ps-btn--fullwidth">
                    {{ isLoading ? "Processing..." : "Verify" }}
                    <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
                    <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
                  </button>
                </div>
              </div>

              <div class="ps-form__footer">

<!--                <p>Connect with:</p>-->
<!--                <ul class="ps-list&#45;&#45;social">-->
<!--                  <li>-->
<!--                    <a class="facebook" href="#"-->
<!--                      ><i class="fa fa-facebook"></i-->
<!--                    ></a>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <a class="google" href="#"-->
<!--                      ><i class="fa fa-google-plus"></i-->
<!--                    ></a>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <a class="twitter" href="#"-->
<!--                      ><i class="fa fa-twitter"></i-->
<!--                    ></a>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <a class="instagram" href="#"-->
<!--                      ><i class="fa fa-instagram"></i-->
<!--                    ></a>-->
<!--                  </li>-->
<!--                </ul>-->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
      },
      error: {
        message: "",
      },
      isLoading: false,
      verify: false,
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      axios.post("verify",{ verification_code: this.form.verification_code }).then((response) => {
          if (response.data.success) {
            this.$router.push({name: 'home'});
          } else {
              this.error.message = "Something went wrong please again";
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status === 401) {
            this.error.message = error.response.data.message;
          }
          if (error.response.status === 400) {
            this.error.message = error.response.data.message;
          }
        //   this.error.message = error.response;
          this.isLoading = false;
        });
    }
  },
};
</script>

<style>
</style>